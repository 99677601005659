import React, { Component } from 'react'
import './App.css'
import Graph from './Graph'

class App extends Component {
  constructor (props) {
    super(props)
    this.graphRef = React.createRef()
    this.inputRef = React.createRef()
    this.handleIdChange = this.handleIdChange.bind(this)
    this.search = this.search.bind(this)
    this.state = {}
  }

  handleIdChange (e) {
    if (e.key === 'Enter') {
      console.log('bla')
      this.search()
    }
  }
  search () {
    const idOrName = this.inputRef.current.value
    if (idOrName.match(/tt\d+/)) {
      this.graphRef.current.getEpisodesById(idOrName)
    } else {
      this.graphRef.current.getEpisodesByName(idOrName)
    }
  }

  render () {
    return (
      <div className='App'>
        <Graph id='tt0108778' name='' ref={this.graphRef} />
        Search:&nbsp;
        <input
          type='text'
          onKeyPress={this.handleIdChange}
          ref={this.inputRef}
        />
        <button onClick={this.search}>Search</button>
      </div>
    )
  }
}

export default App
