import React, { Component } from 'react'

var CanvasJSReact = require('./lib/canvasjs/canvasjs.react')
// var CanvasJS = CanvasJSReact.default.CanvasJS
var CanvasJSChart = CanvasJSReact.default.CanvasJSChart

const axios = require('axios')

class Graph extends Component {
  constructor (props) {
    super(props)
    this.state = {
      options: this.getOptions(null)
    }
    this.getEpisodesById('tt0108778')
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.id !== this.props.id) {
      this.getEpisodesById(nextProps.id)
    }
  }

  getEpisodesById (id) {
    axios.get(`http://localhost:3002/byId/${id}`).then(res => {
      var series = res.data
      this.setState({ ...this.state, options: this.getOptions(series) })
    })
  }

  getEpisodesByName (name) {
    axios.get(`http://localhost:3002/byName/${name}`).then(res => {
      var series = res.data
      this.setState({ ...this.state, options: this.getOptions(series) })
    })
  }

  getOptions (series) {
    if (series === null) {
      series = { title: '', episodes: [] }
    }
    return {
      animationEnabled: true,
      exportEnabled: true,
      theme: 'light2', // "light1", "dark1", "dark2"
      title: {
        text: `Episode Rankings: ${series.title}`
      },
      axisY: {
        title: 'Rating',
        includeZero: false
      },
      axisX: {
        title: 'Episode',
        prefix: '',
        interval: 1000,
        includeZero: true
      },
      data: [
        {
          type: 'scatter',
          click: e => {
            window.open(e.dataPoint.url)
          },
          toolTipContent: 'S{seasonStr}E{episodeStr} {title} - {y}',
          dataPoints: series.episodes.map((ep, idx) => {
            return {
              x: idx + 1,
              y: ep.rating,
              title: ep.title,
              url: ep.imdburl,
              season: ep.season,
              seasonStr: ('' + ep.season).padStart(2, 0),
              episode: ep.episode,
              episodeStr: ('' + ep.episode).padStart(2, 0),
              color: `hsl(${(ep.season * 100) % 360},100%,50%)`
            }
          })
        }
      ]
    }
  }

  render () {
    return (
      <div className='Graph'>
        <CanvasJSChart options={this.state.options} />
      </div>
    )
  }
}

export default Graph
